// method to request device motion
const requestDeviceMotion = () => {
  // return promise
  return new Promise(resolve => {
    // check for explicit permission
    if (typeof DeviceMotionEvent.requestPermission === "function") {
      // request permission
      DeviceMotionEvent.requestPermission().then(state => {
        // resolve by granted state
        resolve(state === "granted" ? "granted" : "rejected")
      }).catch(() => {
        // request failed
        resolve("failed")
      })
    } else {
      // device motion available
      resolve("granted")
    }
  })
}

// mobile device flag
const isMobile = /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent)

/** Device Motion Sensor */
const accelerometer = {
  /** Interval loop initiate id */
  interval: null,
  /** Current sensor type */
  type: null,
  /** Target iframe */
  target: null,
  /** Current motion data */
  data: { isStarted: true, x: 0, y: 0, z: 0 },
  /** Starts motion sensor */
  async start(target) {
    // set target iframe
    this.target = target
    // reset sensor type
    this.type = null
    // check each approach
    if ("Telegram" in window) {
      // set sensor type
      this.type = "telegram"
      // start telegram accelerometer api
      window.Telegram.WebApp.Accelerometer.start({ refresh_rate: 30 })
    } else if (isMobile && typeof DeviceMotionEvent !== "undefined") {
      // request device motion permission
      const state = await requestDeviceMotion()
      // check state
      if (state === "granted") {
        // set sensor type
        this.type = "default"
        // add motion event listener
        window.addEventListener("devicemotion", this.update)
      } else {
        // return false as request failed
        return false
      }
    } else {
      // set sensor type
      this.type = "keyboard"
      // add key event listener
      this.target.contentWindow.addEventListener("keypress", this.update)
    }
    // start interval loop
    this.interval = setInterval(() => {
      // check for telegram
      if (this.type === "telegram") {
        // update callback to update sensor data
        this.update(window.Telegram.WebApp.Accelerometer)
      }
      // check target and window
      if (target && target.contentWindow) {
        // send current data to target element
        target.contentWindow.getAccelerometerData(this.data)
      } else {
        // stop sensor
        this.stop()
      }
    }, 10)
    // return true as sensor started
    return true
  },
  /** Updates motion data */
  update(event) {
    // get origin module
    const origin = accelerometer
    // check event type
    if (event.type === "devicemotion") {
      // get acceleration data
      const acceleration = event.accelerationIncludingGravity
      // update sensor data object
      origin.data.x = acceleration.x * -1
      origin.data.y = acceleration.y
      origin.data.z = acceleration.z
    } else if (event.type === "keypress") {
      // switch by key value
      if (event.key === "a" || event.key === "ArrowLeft") {
        origin.data.x = Math.max(origin.data.x - 12, -6)
      } else if (event.key === "d" || event.key === "ArrowRight") {
        origin.data.x = Math.min(origin.data.x + 12, 6)
      }
    } else {
      // update sensor data from telegram api
      origin.data.x = event.x
      origin.data.y = event.y
      origin.data.z = event.z
    }
  },
  /** Stops motion sensor */
  stop() {
    // clear interval loop
    clearInterval(this.interval)
    // switch by type
    if (this.type === "telegram") {
      // stop telegram api
      window.Telegram.WebApp.Accelerometer.stop()
    } else if (this.type === "default") {
      // remove motion event listener
      window.removeEventListener("devicemotion", this.update)
    } else if (this.type === "keyboard") {
      // remove key event listener
      if (this.target && this.target.contentWindow) {
        this.target.contentWindow.removeEventListener("keypress", this.update)
      }
    }
  }
}

export const sensors = { accelerometer }