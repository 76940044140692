/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useCheckEmailMutation } from "../services/apis/emailAPI"
import { useGetAffiliateMutation, useGetDataMutation } from "../services/apis/commonAPI"
import { setGames, setScreen, setUser } from "../services/store"
import { CircularProgress } from "@mui/material"
import { cognito } from "../modules/cognito"
import EmailPopup from "../components/popups/EmailPopup"
import { storage } from "../modules/storage"
import "./Login.css"

export default function Login() {
  // dispatch
  const dispatch = useDispatch()
  // api mutations
  const [checkEmail] = useCheckEmailMutation()
  const [getData] = useGetDataMutation()
  const [getAffiliate] = useGetAffiliateMutation()
  // loading text
  const [text, setText] = useState("Logging in...")
  // email verify popup
  const [email, setEmail] = useState(false)
  // effect on mount
  useEffect(() => {
    // store affiliate id locally if given
    const params = new URL(window.location.href).searchParams
    const affiliateId = params.get("affiliateid")
    if (affiliateId) { storage.setItem("affiliateid", affiliateId) }
    // store play id locally if given
    const playId = params.get("play")
    if (playId) {
      storage.setItem("play", playId)
      // refresh page to clear url params
      window.location.href = window.location.pathname
    }
    // step delay
    setTimeout(async () => {
      // get cognito status
      const status = cognito.getStatus()
      // check status
      if (status === "logged") {
        // loading app data
        const dataResp = await getData()
        if (dataResp.error) {
          // refresh page on error
          window.location.href = window.location.pathname
          return
        }
        // store user data
        dispatch(setUser(dataResp.data.user_info))
        // store games array
        dispatch(setGames(dataResp.data.game_list))
        // check and open email verify popup if require
        const emailResp = await checkEmail()
        setEmail(emailResp?.error?.status === 400)
        // submit affiliate id if available
        const affiliateid = storage.getItem("affiliateid")
        if (affiliateid) {
          storage.removeItem("affiliateid")
          await getAffiliate({ id: affiliateid })
          // refresh page to clear url params
          window.location.href = window.location.pathname
          return
        }
        // set default hash path
        window.location.href = "#"
        setText("Loading games...")
        // return if email should be verified
        if (emailResp?.error?.status === 400) { return }
        // switch to home screen
        setTimeout(() => dispatch(setScreen("home")), 600)
      } else {
        // redirect to cognito login
        cognito.authenticate("login")
      }
    }, 300)
  }, [])
  return (
    <div className="login">
      <div className="login-content">
        <div className="login-logo" />
        <CircularProgress className="login-spinner" />
        <div className="login-text">
          {text}
        </div>
      </div>
      <EmailPopup
        open={email}
        onClose={() => {
          setEmail(false)
          setTimeout(() => dispatch(setScreen("home")), 300)
        }}
      />
    </div>
  )
}
