import { useEffect, useState } from "react"
import { Dialog } from "@mui/material"
import { ArrowLeft } from "@mui/icons-material"
import {
  useSendEmailOTPMutation,
  useVerifyEmailMutation
} from "../../services/apis/emailAPI"
import "./EmailPopup.css"

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const EmailPopup = ({ open, onClose }) => {
  // mutations
  const [sendOTPMutation] = useSendEmailOTPMutation()
  const [verifyOTPMutation] = useVerifyEmailMutation()
  // popup states
  const [email, setEmail] = useState("")
  const [otp, setOtp] = useState("")
  const [error, setError] = useState("")
  const [state, setState] = useState("email")
  const [wait, setWait] = useState(0)
  const [busy, setBusy] = useState(false)

  const sendOTP = () => {
    if (busy) { return }
    setError("")
    setTimeout(() => {
      if (!email) {
        setError("Email is required")
      } else if (!emailRegex.test(email)) {
        setError("Invalid email address")
      } else {
        setBusy(true)
        sendOTPMutation({ email }).then(resp => {
          if (resp.error) {
            setError("Email submit error")
          } else {
            setState("otp")
            setWait(60)
          }
          setBusy(false)
        })
      }
    }, 80)
  }

  const verifyEmail = () => {
    if (busy) { return }
    setError("")
    setTimeout(() => {
      if (!otp) {
        setError("OTP is required")
      } else {
        setBusy(true)
        verifyOTPMutation({ email, otp }).then(resp => {
          if (resp.error) {
            if (resp.error.status === 400) {
              setError("Invalid OTP")
            } else {
              setError("OTP submit error")
            }
          } else {
            setState("done")
          }
          setBusy(false)
        })
      }
    }, 80)
  }

  useEffect(() => {
    if (wait > 0) {
      setTimeout(() => setWait(wait - 1), 1000)
    }
  }, [wait])

  useEffect(() => setError(""), [email, otp])

  if (state === "email") {
    return (
      <Dialog open={open} fullWidth={true} classes={{ paper: "email-popup" }}>
        <div className="email-popup-header">
          Verify Your Email
        </div>
        <div className="email-popup-image" />
        <div className="email-popup-description">
          Let us know your email to sent the rewards that you will be acquired from 747.
        </div>
        <input
          className="email-popup-input"
          placeholder="Type your email here..."
          disabled={busy}
          value={email}
          onChange={event => setEmail(event.target.value)}
        />
        {
          error && (
            <div className="email-popup-error">
              {error}
            </div>
          )
        }
        <button
          className="email-popup-button"
          disabled={busy}
          onClick={sendOTP}>
          Send OTP
        </button>
      </Dialog>
    )
  } else if (state === "otp") {
    return (
      <Dialog open={open} fullWidth={true} classes={{ paper: "email-popup" }}>
        <div className="email-popup-header">
          <button
            className="email-popup-back-button"
            onClick={() => setState("email")}>
            <ArrowLeft />
          </button>
          Check Your Inbox
        </div>
        <div className="email-popup-image" />
        <div className="email-popup-description">
          <div>
            <b>{email}</b>
          </div>
          <div>
            Please check your inbox for the OTP.
          </div>
        </div>
        <div className="email-popup-description">
          <div>
            <b>Didn't get the OTP?</b>
          </div>
          {
            wait > 0 ? (
              <div>
                Resend code in <b>{wait} seconds</b>.
              </div>
            ) : (
              <div
                className="email-popup-link"
                data-busy={busy}
                onClick={sendOTP}>
                Resend the code
              </div>
            )
          }
        </div>
        <input
          className="email-popup-input"
          placeholder="Type your OTP here..."
          disabled={busy}
          value={otp}
          onChange={event => setOtp(event.target.value)}
        />
        {
          error && (
            <div className="email-popup-error">
              {error}
            </div>
          )
        }
        <button
          className="email-popup-button"
          disabled={busy}
          onClick={verifyEmail}>
          Verify Email
        </button>
      </Dialog>
    )
  } else if (state === "done") {
    return (
      <Dialog open={open} fullWidth={true} classes={{ paper: "email-popup" }}>
        <div className="email-popup-header">
          Verification Complete!
        </div>
        <div className="email-popup-image" />
        <button
          className="email-popup-button"
          onClick={onClose}>
          Close
        </button>
      </Dialog>
    )
  }
}

export default EmailPopup
